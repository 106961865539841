<template>
  <div>
    <Form ref="form" class="editForm" :model="form" :rules="ruleValidate" :label-width="80">
      <FormItem label="审批结果" prop="isPass">
        <Select v-model="form.isPass" clearable placeholder="审批结果">
          <Option v-for="(item,index) in examineList" :value="item.value" :key="index">{{ item.name }}
          </Option>
        </Select>
      </FormItem>
      <FormItem label="审批意见" prop="desc">
        <Input type="textarea" maxlength="128" show-word-limit v-model="form.desc" :autosize="{minRows: 2,maxRows: 3}" placeholder="输入审批意见"></Input>
      </FormItem>
      <FormItem label="附件" prop="fileList">
        <ys-upload :action="config.baseURL+'/resource/UploadFile'" @on-success="bLFile" :headers="{token:token}"></ys-upload>
      </FormItem>
    </Form>
    <div class="modalEditBtnArea">
      <Button type="primary" @click="ok('amend/Check')">提交审批</Button>
      <Button @click="close">取消</Button>
    </div>
  </div>
</template>
<script>
import edit from '@/mixins/edit'

export default {
  name: 'projectEdit',
  mixins: [edit],
  data() {
    return {
      form: {
        id: '',
        isPass: 1,
        fileList: [],
        desc: '',
      },
      examineList: [
        {
          name: "通过",
          value: 1
        },
        {
          name: "不通过",
          value: 0
        }
      ]
    }
  },
  mounted() {
    this.form.id = this.mid;
  },
  methods: {
    bLFile(data) {
      this.form.fileList = data.map(item => item.id);
    },
  }
}
</script>

<style lang="less" scoped>
</style>
