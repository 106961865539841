<template>
  <object ref="chart">
    <div id="taskCommentsPie"></div>
  </object>
</template>

<script>
import * as echarts from 'echarts';
import {getBaseData} from "ys-admin";
import {getProblemsList} from "@/api/problem";

export default {
  name: 'chart',
  props: {
    mid: {
      type: [Number, String, null],
      default: ''
    }
  },
  data() {
    return {
      option: {
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c} ({d}%)'
        },
        series: [
          {
            name: '本次检查安全风险分布',
            type: 'pie',
            radius: '65%',
            center: ['50%', '50%'],
            label: {
              position: 'inside',
              formatter: '{name|{b}}\n{time|{c}个,{d}%}',
              minMargin: 0,
              edgeDistance: 10,
              lineHeight: 15,
              rich: {
                time: {
                  fontSize: 14,
                  color: '#999'
                }
              }
            },
            itemStyle: {
              borderRadius: 5
            },
            data: []
          }
        ]
      },
      myChart: ''
    }
  },
  computed: {},
  watch: {},
  mounted() {
    this.myChart = null;
    window.setTimeout(() => {
      this.myChart = echarts.init(document.getElementById('taskCommentsPie'));
      if (this.mid) this.getProblemsList();
      window.addEventListener("resize", () => {
        this.myChart.resize();
      })
    }, 500)
  },
  methods: {
    getProblemsList() {
      this.option.series[0].data = [];
      getProblemsList({checkId: this.mid, isTop: false, noArea: true, limit: -1}).then((res) => {
        if (res.code === 200) {
          let mapData = {};
          (res.data.list || []).forEach((item) => {
            if (!mapData[String(item.riskGrade)]) {
              mapData[String(item.riskGrade)] = {
                name: getBaseData('风险等级', item.riskGrade).name,
                value: 0,
                itemStyle: {
                  color: getBaseData('风险等级', item.riskGrade).color
                }
              };
            }
            mapData[String(item.riskGrade)].value = mapData[item.riskGrade].value + 1
          })
          Object.keys(mapData).forEach((item) => {
            this.option.series[0].data.push(mapData[String(item)])
          })
          this.myChart.setOption(this.option);
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>

#taskCommentsPie {
  height: 350px;
  width: 100%;
  text-align: center;
}
</style>
