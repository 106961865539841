<template>
  <div class="home">
    <ys-page-list
        ref="table"
        action="/comment/List"
        :params="params"
        :tableHeight="tableHeight"
        @on-selection-change="checkItem"
        :multiBtn="multiBtn"
        :multiColumn="multiColumn"
        :headerColumns="headerColumns">
      <template slot="search">
        <FormItem label="风险等级" prop="riskGrade">
          <Select v-model="params.riskGrade" clearable placeholder="风险等级" style="width: 100px;">
            <Option v-for="(item,index) in baseData['风险等级']" :style="{'color':item.color }" :value="item.value" :key="index">{{ item.name }}</Option>
          </Select>
        </FormItem>
        <FormItem label="隐患整改状态" prop="status">
          <Select v-model="params.status" clearable placeholder="整改状态" style="width: 100px;">
            <Option v-for="(item,index) in baseData['隐患状态']" :style="{'color':item.color }" :value="item.value" :key="index">{{ item.name }}</Option>
          </Select>
        </FormItem>
      </template>
      <template slot="detail">
        <Detail :mid="detailID"></Detail>
      </template>
    </ys-page-list>
  </div>
</template>

<script>
import pageList from '@/mixins/pageList';
import Detail from "@/views/problems/detail.vue";

export default {
  name: 'problems',
  mixins: [pageList],
  components: {Detail,},
  props: {
    projectId: {type: Number, default: 0},
  },
  data() {
    return {
      params: {
        checkId: '',
        riskGrade: '',
        type: '',
        amendId: '',
        projectId: '',
        categoryId: '',
        dictId: '',
        itemId: '',
        amendType: '',
        amendStatus: '',
        status: '',
        keyword: '',
      },
      headerColumns: [
        {type: 'selection', title: '选择框', width: 60, align: 'center'},
        {type: 'index', title: '序号', width: 70, align: 'center'},
        {width: 150, title: '任务名称', key: 'checkName', align: 'center',},
        {width: 150, title: '隐患整改状态', key: 'status', align: 'center', renderConfig: {type: 'baseData', parentName: '隐患状态'}},
        {width: 220, title: '风险项目', key: 'itemName', align: 'left', renderConfig: {type: 'gotoDetail'}},
        {width: 120, title: '检查项目', key: 'categoryName', align: 'left'},
        {width: 100, title: '检查专家', key: 'expertName', align: 'center'},
        {width: 110, title: '发现时间', key: 'serveTime', align: 'center', renderConfig: {type: 'formatDate'}},
        {
          width: 130, title: '风险辨别(扣分项)', key: 'scope', align: 'center', render: (h, params) => {
            return h('span', String(params.row.scope === -1 ? '-' : params.row.scope))
          }
        },
        {width: 80, title: '风险等级', key: 'riskGrade', align: 'center', renderConfig: {type: 'baseData', parentName: '风险等级'}},
        {minWidth: 230, title: '风险描述', tooltip: true, key: 'remarksText', align: 'left'},
      ]
    }
  },
  computed: {
    multiBtn() {
      return [
        {
          hiddenBtnIcon: true,
          children: [
            {
              type: 'detail',
              title: '查看详情',
              icon: 'md-eye',
              disabled: this.tableSelectItem.length !== 1
            },
          ]
        },
      ]
    }
  },
  mounted() {
    this.params.amendId = this.mid;
    this.params.projectId = this.projectId;
    this.params.amendStatus = this.projectId ? 2 : '';
    this.getList()
  }
}
</script>
